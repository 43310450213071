@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600;700&family=Roboto:wght@400;500;700&display=swap');
@import './icomoon.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

    body{
        @apply text-primary text-default;
    }


    .footerNav{
        @apply flex justify-center items-center gap-6 mb-3 md:mb-0;
    }
    .footerNav > li a{
        @apply font-sans font-medium text-white text-opacity-70 hover:text-opacity-100 transition duration-150 ease-in;
    }


    .headerNav{
        @apply flex flex-col lg:flex-row justify-center items-center gap-6;
    }
    .headerNav > li a{
        @apply font-serif font-medium text-primary text-opacity-100 hover:text-opacity-70 hover:text-secondary-hover transition duration-150 ease-in;
    }
    .headerNav > li.active a{
        @apply text-secondary;
    }


    .footerSocial{
        @apply flex justify-center md:justify-end items-center gap-6;
    }
    .footerSocial > li a{
        @apply text-xl font-sans font-medium text-white text-opacity-70 hover:text-opacity-100 transition duration-150 ease-in;
    }


    .button{
        @apply border-[1px] border-primary text-sm rounded-lg flex justify-center items-center px-6 py-3 font-serif font-medium outline-none transition duration-150 ease-in;
    }
    .button.primary{
        @apply border-primary bg-primary hover:bg-primary-hover hover:border-primary-hover text-white focus:opacity-70;
    }
    .button.secondary{
        @apply border-secondary bg-secondary hover:bg-secondary-hover hover:border-secondary-hover text-primary focus:opacity-70;
    }
    .button.white{
        @apply border-white bg-white text-primary hover:text-primary-hover focus:opacity-70;
    }
    .button.primary.outline{
        @apply bg-transparent text-primary hover:bg-primary-hover hover:border-primary-hover hover:text-white focus:opacity-70;
    }
    .button.secondary.outline{
        @apply bg-transparent text-secondary hover:bg-secondary-hover hover:border-secondary-hover hover:text-primary focus:opacity-70;
    }
    .button:disabled{
        @apply opacity-50;
    }


    /* card */
    .card{
        @apply rounded-3xl p-12 md:p-16 bg-cover;
    }
    .card h2{
        @apply font-serif font-bold text-3xl lg:text-4xl mb-6 max-w-xl lg:leading-[48px];
    }
    .card h3{
        @apply font-serif font-bold text-xl lg:text-3xl mb-6 max-w-xl;
    }
    .card p{
        @apply mb-6 max-w-xl;
    }

    .card.light h2,
    .card.light h3,
    .card.light p{
        @apply text-primary;
    }
    .card.dark h2,
    .card.dark h3,
    .card.dark p{
        @apply text-white;
    }
    

    /* label */
    .label{
        @apply rounded-lg px-4 py-1 flex justify-center items-center transition duration-150 ease-in text-xs;
    }
    .label.dark{
        @apply bg-gray-dark text-white hover:opacity-75;
    }
    .label.light{
        @apply bg-gray-lightLabel text-primary hover:opacity-75;
    }
    .label.light.alt{
        @apply bg-gray-lightLabelAlt text-white hover:opacity-75;
    }


    /* nft item */
    .nftItem{
        @apply rounded-3xl bg-cover relative overflow-hidden cursor-pointer;
    }
    .nftItem:after{
        content:'';
        padding-bottom:100%;
        @apply block;
    }
    .nftItem .overlay{
        @apply absolute top-0 left-0 right-0 bottom-0 bg-primary bg-opacity-90 flex flex-col justify-center items-center text-center text-white font-serif font-bold opacity-0 transition duration-200;
    }
    .nftItem.active .overlay,
    .nftItem.selected .overlay{
        @apply opacity-100;
    }
    .nftItem .overlay span{
        @apply text-sm opacity-70;
    }
    .nftItem .action{
        @apply absolute top-5 left-5 text-white text-2xl;
    }



    .navicon{
      @apply relative w-[30px] h-[26px] mr-3;
    }
    .navicon > i{
      @apply absolute left-0 w-[30px] h-[2px] bg-primary transition-all duration-150 ease-in;
    }
    .navicon > i:nth-child(1){
      @apply top-1;
    }
    .navicon > i:nth-child(2){
      @apply top-3;
    }
    .navicon > i:nth-child(3){
      @apply top-5;
    }
    .navicon.active > i:nth-child(1){
      @apply rotate-45 top-3;
    }
    .navicon.active > i:nth-child(2){
      @apply opacity-0;
    }
    .navicon.active > i:nth-child(3){
      @apply -rotate-45 top-3;
    }


    .mobileMenu{
      @apply fixed top-[96px] left-0 right-0 h-0 overflow-hidden bg-white z-50 transition-all duration-200 ease-in px-5 flex flex-col justify-center items-center;
    }
    .mobileMenu.active{
      height:calc(100% - 56px);
      @apply overflow-y-scroll;
    }
    .mobileMenu >div{
      @apply pt-5 pb-5 flex flex-col justify-center items-center;
    }



    /* Loading */
    ._loading{
      @apply fixed top-0 left-0 right-0 bottom-0 bg-white bg-opacity-50 opacity-100 z-50 flex justify-center items-center transition-opacity delay-150 ease-in;
    }
    ._loading.hide{
      z-index: -1;
      @apply opacity-0;
    }
    ._loading > i{
      @apply animate-spin text-4xl text-primary;
    }


    /* modal */
    .modal{
      @apply fixed z-50 top-0 left-0 right-0 bottom-0 bg-white bg-opacity-50 flex justify-center items-center;
    }
    .modal > div{
      @apply bg-white shadow-lg p-8 max-w-4xl rounded-xl relative w-full;
    }
    .modal.small > div{
      @apply max-w-sm;
    }
    .modal.medium > div{
      @apply max-w-md;
    }
    .modal > div button.close{
      @apply absolute top-4 right-4 p-1 bg-gray-100 text-black rounded-lg flex justify-center items-center w-7 h-7 hover:bg-primary hover:text-white;
    }
    .modal > div button.close i{
      @apply text-xs;
    }


    /* forms */
    .formGroup{
        @apply mt-2 mb-3;
    }
    .formGroup > label{
        @apply block text-black pt-2 pb-1 text-sm opacity-50;
    }
    .formGroup .formControl{
        @apply py-2 px-3 text-black border-gray-100 border-2 bg-gray-100 border-opacity-0 w-full outline-none focus:border-gray-200 focus:bg-gray-200 h-12 rounded-lg;
    }
    .formGroup .formControl.error{
        @apply border-red-500;
    }
    .formGroup > span.error{
        @apply text-sm font-thin text-red-500;
    }

}
